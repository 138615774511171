// import logo from "./logo.svg";
import "./App.css";
import Footer1 from "./components/Footer1";
import Twitter from "./components/Twitter";
// import Home from "./pages/Home";
// import Card from "./components/Card";

function App() {
  return (
    <div className="App">
      <div>
        {/* <Home /> */}
        {/* <Card /> */}
        {/* <Twitter /> */}
        {/* <Footer1 /> */}
      </div>

      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
    </div>
  );
}

export default App;
